import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from "gatsby-plugin-mdx"
import Container from '../components/container'
import logo from '../images/coronacamp.svg'

export default ({ data: { mdx } }) => {
  return (
    <Container>
      <div className="mt-20 mb-10 text-center">
        <Link to="/">
          <img className="inline-block" width={261} src={logo} alt="Corona Camp" />
        </Link>
      </div>
      <MDXRenderer>{mdx.body}</MDXRenderer>
    </Container>
  )
}

export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
      }
    }
  }
`
